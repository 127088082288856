import { useShowroomQuery } from '@/features/showroom/queries/useShowroomQuery'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'

export type IUrgentKlass = {
  id: string
  title: string
  image: string | null
  deadline: number | string
}

const getUrgentKlassList = () => {
  const today = dayjs()
  const { data: showroomList, isLoading: isShowRoomListLoading } = useShowroomQuery('home', 'kdt')
  const [isLoading, setIsLoading] = useState(true)
  const [urgentKlassList, setUrgentKlassList] = useState<IUrgentKlass[]>([])

  useEffect(() => {
    if (!isShowRoomListLoading && showroomList) {
      const list = showroomList[0].courses
        .map(course => {
          const endDay = dayjs(course.applyEndedAt)
          const daysLeft = endDay.startOf('day').diff(today.startOf('day'), 'day')

          return {
            id: course.id,
            title: course.title,
            image: course.dashboardImage,
            deadline: daysLeft === 0 ? 'DAY' : daysLeft,
            daysLeft,
          }
        })
        .filter(course => course.daysLeft <= 7 && course.daysLeft >= 0)

      setUrgentKlassList(list)
      setIsLoading(false)
    }
  }, [showroomList])

  return {
    urgentKlassList,
    isUrgentKlassListLoaidng: isLoading,
  }
}

export default getUrgentKlassList

import ChannelService from '@/shared/utils/channeltalk'
import { useEffect, useState } from 'react'

interface IMobileFloatingSideBarProps {
  isDetailPage?: boolean
}

const MobileFloatingSideBar = ({ isDetailPage }: IMobileFloatingSideBarProps) => {
  const channelService = ChannelService()
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    channelService?.open(() => {
      setIsOpen(true)
    })
    channelService?.close(() => {
      setIsOpen(false)
    })
  }, [channelService])

  return (
    <>
      {!isOpen && (
        <button
          className={`${isDetailPage ? 'bottom-[106px]' : 'bottom-4'} fixed right-4 z-100 lg:bottom-6 lg:right-6`}
          onClick={() => channelService?.show()}
        >
          <div className="relative z-100 flex h-12 w-12 items-center justify-center overflow-hidden rounded-full bg-white p-0.5 shadow-[2.182px_2.36px_15.91px_0px_rgba(0,0,0,0.10)] lg:h-[52px] lg:w-[52px]">
            <img
              className="w-7 object-contain lg:w-[30px]"
              src={'https://d35ai18pny966l.cloudfront.net/course/bootcamp_index/icon_chat.png'}
              alt="-"
            />
          </div>
          <div className="absolute right-0.5 top-[3px] flex h-2 w-2 items-center justify-center">
            <span className="relative z-100 inline-block h-full w-full rounded-full bg-status-negative"></span>
          </div>
        </button>
      )}
    </>
  )
}

export default MobileFloatingSideBar

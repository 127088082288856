import { IUrgentKlass } from '@/features/klass/hooks/getUrgentKlassList'
import Image from 'next/image'
import Link from 'next/link'

interface IUrgentKlassQuickItemProps {
  klassList: IUrgentKlass[]
}
const UrgentKlassQuickItem = ({ klassList }: IUrgentKlassQuickItemProps) => {
  return (
    <>
      {klassList.slice(0, 2).map(({ id, title, image, deadline }) => {
        const schoolTitleNoNumber = title.replaceAll(/[0-9]/g, '')
        const schoolTitle = schoolTitleNoNumber.replaceAll(/[기]/g, '')
        return (
          <Link
            key={`사이드바_${id}`}
            href={`/school/${id}`}
            className="flex cursor-pointer flex-col justify-center gap-1.5"
          >
            <div className="relative flex flex-col items-center justify-center">
              <div className="absolute -top-2 right-0 whitespace-nowrap rounded-full bg-yellow-450 px-1.5 py-0.5 text-xs font-semibold text-white">
                D-{deadline}
              </div>
              <Image
                alt="지원중_KDT"
                src={
                  image ||
                  'https://d35ai18pny966l.cloudfront.net/course/notice_boards/empty_file_img.png'
                }
                className="rounded-2xl"
                width={42}
                height={42}
                style={{
                  width: '42px',
                  height: '42px',
                  objectFit: 'cover',
                  objectPosition: 'center',
                }}
              />
            </div>
            <div className="text-center text-sm font-medium text-[#1E1E1E]">
              <p className="truncate">{schoolTitle}</p>
              <p>지원하기</p>
            </div>
          </Link>
        )
      })}
    </>
  )
}

export default UrgentKlassQuickItem
